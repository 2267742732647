/* Add this to your CSS file */
.spinner {
    border: 4px solid rgba(146, 234, 132, 0.8);
    width: 72px;
    height: 72px;
    border-radius: 80%;
    border-left-color: transparent;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
