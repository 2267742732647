.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


:root {
    --transition-duration: .6s;
}

.toggle-animate {
    transition: transform var(--transition-duration) ease-in-out;
}

.form-slide-left {
    transform: translateX(0%);
}

.form-slide-right {
    transform: translateX(100%);
}

.image-slide-left {
    transform: translateX(-100%);
}

.image-slide-right {
    transform: translateX(0%);
}

/* Assuming this class is applied to the image or its container */
.image-opacity {
    background-color: rgba(255, 255, 255, 0.15);
}

/* Animation */
@keyframes opacityToggle {
    0%, 100% {
        background-color: rgba(255, 255, 255, 0.15);
    }
    50% {
        background-color: rgb(199, 215, 199);
    }
}

@keyframes scorePulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2); /* Adjust this value to control the size it grows to */
    }
    100% {
        transform: scale(1);
    }
}

.score-animation {
    animation: scorePulse 1s ease; /* Adjust time as needed */
}


.opacity-animate {
    animation: opacityToggle var(--transition-duration) ease-in-out forwards;

}


